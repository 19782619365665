import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import Popup from "reactjs-popup";
import {CopyToClipboard} from 'react-copy-to-clipboard';

import {offsetDateForServer} from '../../../shared/timeZoneLogic';

import ConfirmActionMenu from './ConfirmActionMenu';
import HoldActionMenu from './HoldActionMenu';
import ManageLineup from './ManageLineup';
import FolderViewApp from '../../FolderView/startup/FolderViewApp';
import ManageNotes from './ManageNotes';
import ManageShowActivities from './ManageShowActivities';
import ManageContacts from './ManageContacts';
import ManageTickets from './ManageTickets';
import ManageOpendateTickets from './ManageOpendateTickets';
import CopyLinks from './CopyLinks';
import ManageOffers from './ManageOffers';
import ManageSettlements from './ManageSettlements';
import MarketingSection from './MarketingSection';
import ProfitAndLoss from './ProfitAndLoss';
import ShowAdvanceSendPopup from './ShowAdvanceSendPopup';
import ShareSettingsPopup from './ShareSettingsPopup';
import SetPermissionsPopup from "../../SetPermissionsPopup/components/SetPermissionsPopup";
import ManageFinanceItems from './ManageFinanceItems';
import EventDetailsApp from '../../EventDetails/startup/EventDetailsApp';
import TagManagerApp from '../../TagManager/startup/TagManagerApp';
import EventForm from '../../EventForm/components/EventForm';

const axios = require('axios').default;

const newContentStyle = (browser) => {
  var styles = {
    "border": "none",
    "borderRadius": "8px",
    "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
    "width": "594px",
    "maxWidth": ((browser.is.small || browser.is.extraSmall) ? "100vw" : "594px"),
    "overflow": "hidden",
    "zIndex": '200'
  }

  if (browser.greaterThan.small){
    styles["minWidth"] = "530px";
  }

  return styles;
}

const changelogPopupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "620px",
  "height": "300px",
  "overflow": "auto",
  "padding": "25px 20px"
}

const embedPopupStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "960px",
  "padding": "0px"
}

const getAllCalendarEventAttachments = (folderedItems) => {
  var calendarEventAttachments = [];

  folderedItems.map((foldered) => {
    var itemsToAdd;

    if(foldered.item.is_folder){
      itemsToAdd = getAllCalendarEventAttachments(foldered.item.foldered_items);
    } else {
      itemsToAdd = foldered.item;
    }

    calendarEventAttachments.push(itemsToAdd);
  });

  return calendarEventAttachments.flat();
}

const hasManuallyAddedCalendarEventAttachments = (calendarEventAttachments) => {
  return calendarEventAttachments.some((calendarEventAttachment) => {
    return !calendarEventAttachment.auto_added;
  });
}

const offersWithSettlements = (offers) => {
  return offers.filter((o) => o.ui_version === 2);
}

const getConfirmPageSection = (confirmPageSections, identifier) => {
  return confirmPageSections.find((cps) => cps.identifier === identifier);
}

const ConfirmShow = ({
  browser,
  confirm,
  confirmChanged,
  refreshConfirm,
  performers,
  deletePerformer,
  csrfToken,
  team,
  user,
  stagePerformerForUpdate,
  updatePerformer,
  updatePerformerActType,
  performerStartTimeChanged,
  performerEndTimeChanged,
  addNewPerformer,
  setArtistOnNewPerformer,
  createPerformer,
  cancelAddPerformer,
  originalPerformer,
  cancelEditPerformer,
  currentUser,
  openNote,
  openNoteChanged,
  fetchNewNote,
  closeOpenNote,
  openNoteTitleChanged,
  openNoteBodyChanged,
  createNote,
  notes,
  noteTemplates,
  noteTemplatesChanged,
  deleteNote,
  fetchNoteToEdit,
  updateNote,
  openNoteOriginal,
  openNoteLoading,
  fetchNoteToPreview,
  disableOpenNotePreviewMode,
  calendarEventContacts,
  createCalendarEventContactFromContactId,
  deleteCalendarEventContact,
  addCalendarEventContact,
  removeUnsavedCalendarEventContact,
  editCalendarEventContact,
  cancelEditCalendarEventContact,
  updateCalendarEventContactName,
  updateCalendarEventContactEmail,
  updateCalendarEventContactPhone,
  updateCalendarEventContactContactType,
  updateCalendarEventContactTitle,
  updateCalendarEventContactCompanyName,
  submitCalendarEventContactForm,
  editUnsavedCalendarEventContact,
  originalCalendarEventContact,
  createCalendarEventContactFromAgentId,
  modalOffer,
  defaultOffer,
  offerModalIsOpen,
  closeOfferModal,
  openOfferModal,
  offerTemplates,
  venuesOptions,
  onManageLineupInit,
  fetchOfferData,
  refreshPerformer,
  offerPerformer,
  setOfferPerformer,
  acceptOffer,
  declineOffer,
  offers,
  refreshConfirmOffers,
  deleteOffer,
  folder,
  updateConfirmWebsite,
  updateConfirmTicketForecast,
  deleteConfirm,
  cancelConfirm,
  sendingCustomerIOEmail,
  sendingCustomerIOEmailChanged,
  customerIOEmailPurpose,
  customerIOEmailPurposeChanged,
  customerIOEmailTemplate,
  customerIOEmailTemplateChanged,
  createCustomerIOEmail,
  marketingSectionActiveTab,
  marketingSectionActiveTabChanged,
  authorizedPages,
  addingNewSocialAccount,
  addingNewSocialAccountChanged,
  onepostPublicKey,
  twitterProviderId,
  facebookProviderId,
  composingPost,
  composingPostChanged,
  createPost,
  posts,
  deletePost,
  postToEdit,
  postToEditChanged,
  updatePost,
  updateCalendarEventContact,
  fetchConfirmShowActivities,
  showActivities,
  deleteShowActivity,
  editShowActivity,
  cancelEditShowActivity,
  addNewShowActivity,
  showActivityNameChanged,
  showActivityStartDateChanged,
  showActivityStartTimeChanged,
  showActivityCopy,
  createShowActivity,
  updateShowActivity,
  showAdvanceSend,
  showAdvanceSendChanged,
  showAdvanceSendToChanged,
  showAdvanceSendSubjectChanged,
  showAdvanceSendBodyChanged,
  createShowAdvanceSend,
  showAdvanceSendNoteClicked,
  venueSelected,
  confirmInvitation,
  confirmInvitationChanged,
  confirmInvitationMemoChanged,
  emailsToInvite,
  emailsToInviteChanged,
  guestRole,
  createConfirmInvitations,
  pendingConfirmInvitations,
  guestTeamMemberships,
  isViewingGuests,
  isViewingGuestsChanged,
  deleteConfirmInvitation,
  removeConfirmAccess,
  shareSettingsPopupClosed,
  selfRegisterLink,
  offerForSettlement,
  offerForSettlementChanged,
  permissionSet,
  permissionSetChanged,
  fetchPermissionSet,
  permissionRuleEnabledClicked,
  selectAllPermissionRulesClicked,
  submitPermissionSet,
  onPermissionSetSaved,
  noteUpdated,
  confirmPageSections,
  updateConfirmPageSection,
  promotersWithPermission,
  showAdvanceSends,
  socialMarketingBudgetItems,
  traditionalMarketingBudgetItems,
  addSocialMarketingBudgetItem,
  addTraditionalMarketingBudgetItem,
  createMarketingBudgetItem,
  destroyMarketingBudgetItem,
  editMarketingBudgetItem,
  updateMarketingBudgetItem,
  marketingBudgetItemToEdit,
  marketingBudgetItemToEditChanged,
  financeItems,
  financeItemsChanged,
  financeCategories,
  financeCategoriesChanged,
  fetchNewFinanceItem,
  deleteFinanceItem,
  financeItemToEdit,
  financeItemToEditChanged,
  financeItemToEditCanceled,
  financeItemForForm,
  financeItemForFormChanged,
  createFinanceItem,
  updateFinanceItem,
  ticketTypesByPrice,
  showWidgetCode,
  showWidgetCodeChanged,
  addOnsByPrice,
  uncancelConfirm,
  buyers,
  buyersChanged,
  selectedBuyer,
  selectedBuyerChanged,
  promoters,
  promotersChanged,
  selectedPromoter,
  selectedPromoterChanged,
  venueOwnerships,
  editingConfirmWebsite,
  editingConfirmWebsiteChanged,
  editingConfirmWebsiteValue,
  editingConfirmWebsiteValueChanged,
  fetchOfferTotalDue,
  offerChanged,
  updateOfferArtist,
  folderChanged,
  teamRootFolder,
  profitAndLoss,
  fetchProfitAndLoss,
  updateDeposit,
  mayCancelConfirm,
  mayUncancelConfirm,
  holdGroupDeletion,
  holdGroupDeletionChanged,
  deleteHoldGroup,
  holdToConfirm,
  holdToConfirmChanged,
  applyTimeDefaults,
  doorsTimeDefault,
  eventEndTimeDefault,
  userChangedDoorsTime,
  userChangedDoorsTimeChanged,
  userChangedEventEndTime,
  userChangedEventEndTimeChanged,
  convertHoldGroupToConfirm,
  isConvertingHoldToConfirm,
  possibleMatchingOffers,
  fetchPossibleMatchingOffers,
  dismissMatchingOffers,
  acceptMatchingOffer,
  manualTicketCounts,
  railsEnv,
  showUpgradeLink,
  showDonationLink,
  calendarEventNoteEditorState,
}) => {
  const [confirmToDup, setConfirmToDup] = useState(null)
  const [userChangedDupDoorTime, setUserChangedDupDoorTime] = useState(false)
  const [userChangedDupEndTime, setUserChangedDupEndTime] = useState(false)
  const [submittingDuplicationForm, setSubmittingDuplicationForm] = useState(false)

  const submitDuplicationForm = () => {
    const stateToHTML = require('draft-js-export-html').stateToHTML;

    if(confirmToDup) {
      axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

      setSubmittingDuplicationForm(true);

      axios.post(`/teams/${team.id}/confirms/${confirm.id}/duplicate`, {
        confirm: {
          title: confirmToDup.title,
          venue_id: confirmToDup.venue.id,
          artist_ids: confirmToDup.performers.map(performer => performer.artistable.id),
          promoter_id: confirmToDup.promoter?.value,
          buyer_id: confirmToDup.buyer?.value,
          start_time: offsetDateForServer(confirmToDup.start_time),
          end_time: offsetDateForServer(confirmToDup.end_time),
          doors_time: offsetDateForServer(confirmToDup.doors_time),
          ticket_forecast: confirmToDup.ticket_forecast,
          note_attributes: {
            body: stateToHTML(confirmToDup.note_state.getCurrentContent())
          }
        }
      })
      .then(response => {
        window.location.href = `/teams/${team.id}/confirms/${response.data.id}`
      })
      .catch(errors => {
        setConfirmToDup({
          ...confirmToDup,
          errors: errors.response.data
        })
      })
      .finally(() => setSubmittingDuplicationForm(false))
    }
  }

  var canShowManageOffersSection = (
    team.package.pipeline
      && getConfirmPageSection(confirmPageSections, "offers")
  );

  var confirmInvitationsAndTeamMemberships = pendingConfirmInvitations
    .concat(guestTeamMemberships)
    .sort((a, b) => a.name_with_email.localeCompare(b.name_with_email));

  var widgetCode = `<script src="https://app.opendate.io/packs/od_embed.js"></script>
<iframe src="https://app.opendate.io/confirms/` + confirm.id + `/web_orders/new" id="od-confirm-` + confirm.id + `-iframe" title="` + confirm.title + `" scrolling="no" allowpaymentrequest="true" style="border:none; width: 1px; min-width: 100%;" height: 600px;></iframe>
<script type="text/javascript">
  ODEmbed("od-confirm-` + confirm.id + `-iframe");
</script>`

  useEffect(() => {
    offersWithSettlements(offers)
      .forEach(offer => fetchOfferTotalDue(team, offer));

    if(canShowManageOffersSection){
      fetchPossibleMatchingOffers(csrfToken, team, confirm);
    }
  }, [])

  useEffect(() => {
    applyTimeDefaults(
      holdToConfirm,
      doorsTimeDefault,
      eventEndTimeDefault,
      !userChangedDoorsTime,
      !userChangedEventEndTime,
      (holdToConfirm) => {
        holdToConfirmChanged(holdToConfirm);
      }
    );
  }, [holdToConfirm.start_time])

  useEffect(() => {
    if(confirmToDup?.start_time) {
      applyTimeDefaults(
        confirmToDup,
        doorsTimeDefault,
        eventEndTimeDefault,
        !userChangedDupDoorTime,
        !userChangedDupEndTime,
        (updatedConfirmToDup) => {
          setConfirmToDup(updatedConfirmToDup);
        }
      );
    }
  }, [confirmToDup?.start_time])

  return (
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col-12 col-md">
          <div className="page-title d-flex align-items-center">
            { confirm.name }
            {confirm.calendar_classification === "past" ? (
              <span className="badge badge-secondary mt-1 ml-3 p-2"
                    style={{"background": "#919191", "color": "white", "fontSize": "10px"}}>
                Past
              </span>
            ) : confirm.calendar_classification === "canceled" ? (
              <span className="badge badge-danger mt-1 ml-3 p-2"
                    style={{"fontSize": "10px"}}>
                Canceled
              </span>
            ) : confirm.calendar_classification === "on_sale" ? (
              <span className="badge badge-secondary mt-1 ml-3 p-2"
                    style={{"background": "#5f5383", "color": "white", "fontSize": "10px"}}>
                On Sale
              </span>
            ) : confirm.calendar_classification === "announced" ? (
              <span className="badge badge-secondary mt-1 ml-3 p-2"
                    style={{"background": "#98c648", "color": "white", "fontSize": "10px"}}>
                Announced
              </span>
            ) : confirm.calendar_classification === "hold" ? (
              <span className="badge badge-secondary mt-1 ml-3 p-2"
                    style={{"background": "#f7ca58", "color": "#333333", "fontSize": "10px"}}>
                Hold
              </span>
            ) : (
              <span className="badge badge-secondary mt-1 ml-3 p-2"
                    style={{"background": "#4c81b9", "color": "white", "fontSize": "10px"}}>
                Confirmed
              </span>
            )}
          </div>
          <EventDetailsApp
            csrfToken={csrfToken}
            team={team}
            currentUser={currentUser}
            confirm={{...confirm}}
            confirmSaved={savedConfirm => confirmChanged(savedConfirm)}
            buyers={[...buyers]}
            promoters={promoters}
            financeItemsChanged={financeItemsChanged} />
        </div>
        {currentUser.can_menu_calendar_event ? (
          <div className="col-12 col-md-auto"
               style={{"paddingRight": "15px"}}>
            <div className="row h-100">
              <div className="col d-flex">
                {confirm.human_last_version_created_at ? (
                  <Popup arrow={false}
                        offsetY={5}
                        position="bottom right"
                        contentStyle={changelogPopupContentStyle}
                        on={"hover"}
                        trigger={open => (
                          <a href="#"
                             onClick={
                               (e) => {
                                 e.preventDefault();
                               }
                             }
                             style={{"marginBottom": "16px"}}
                             className="text-muted align-self-end pr-5">
                            Last edit was {confirm.last_version_created_at_ago_in_words} ago at {confirm.human_last_version_created_at}
                          </a>
                        )} >
                   {close => (
                     <div className="row">
                       <div className="col-12">
                         <strong style={{"fontSize": "16px"}}>
                           Changelog
                         </strong>
                         <table className="table table-borderless small mt-3 mb-0">
                           <tbody>
                             {confirm.serialized_versions.map((version, index) => (
                               <tr key={index}>
                                 <td className="pr-0 pb-0 pt-1 pl-0" style={{"width": "100px"}}>
                                   <div>{version.date}</div>
                                   <div className="text-muted">{version.time}</div>
                                  </td>
                                 <td className="pr-0 pb-0 pt-1">{version.message}</td>
                               </tr>
                             ))}
                           </tbody>
                         </table>
                       </div>
                     </div>
                   )}
                  </Popup>
                ) : null}
              </div>
              <div className="col-xs-auto d-flex" style={{"paddingRight": "15px"}}>
                {confirm.type === "Confirm" ? (
                  <ConfirmActionMenu confirm={confirm}
                                  csrfToken={csrfToken}
                                  team={team}
                                  currentUser={currentUser}
                                  deleteConfirm={deleteConfirm}
                                  cancelConfirm={cancelConfirm}
                                  showAdvanceSendChanged={showAdvanceSendChanged}
                                  confirmInvitationChanged={confirmInvitationChanged}
                                  uncancelConfirm={uncancelConfirm}
                                  mayCancelConfirm={mayCancelConfirm}
                                  mayUncancelConfirm={mayUncancelConfirm}
                                  setConfirmToDup={setConfirmToDup}
                                  selectedPromoter={selectedPromoter}
                                  selectedBuyer={selectedBuyer} />
                ) : (
                  <HoldActionMenu confirm={confirm}
                                  csrfToken={csrfToken}
                                  team={team}
                                  holdGroupDeletion={holdGroupDeletion}
                                  holdGroupDeletionChanged={holdGroupDeletionChanged}
                                  deleteHoldGroup={deleteHoldGroup}
                                  holdToConfirm={holdToConfirm}
                                  holdToConfirmChanged={holdToConfirmChanged}
                                  userChangedDoorsTimeChanged={userChangedDoorsTimeChanged}
                                  userChangedEventEndTimeChanged={userChangedEventEndTimeChanged}
                                  convertHoldGroupToConfirm={convertHoldGroupToConfirm}
                                  isConvertingHoldToConfirm={isConvertingHoldToConfirm}
                                  currentUser={currentUser} />
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {currentUser.can_read_tagged_items ? (
        <TagManagerApp
          csrfToken={csrfToken}
          team={team}
          currentUser={currentUser}
          scope="CalendarEvent"
          title={`Tags for Event`}
          taggable={confirm}
          tagsButtonPosition="left"
          activeTagsAlignment="left"
          popupClasses="px-0 col-12 col-md-4 col-lg-6 position-absolute shadow"
        />
      ) : null}
      <div className="row">
        <div className="col-12 col-md-6 col-lg-8 side-by-side-col left">
          <div className="card no-border shadow-2">
            {getConfirmPageSection(confirmPageSections, "lineup") ? (
              <React.Fragment>
                <ManageLineup performers={performers}
                              currentUser={currentUser}
                              deletePerformer={deletePerformer}
                              csrfToken={csrfToken}
                              team={team}
                              stagePerformerForUpdate={stagePerformerForUpdate}
                              updatePerformer={updatePerformer}
                              updatePerformerActType={updatePerformerActType}
                              performerStartTimeChanged={performerStartTimeChanged}
                              performerEndTimeChanged={performerEndTimeChanged}
                              addNewPerformer={addNewPerformer}
                              setArtistOnNewPerformer={setArtistOnNewPerformer}
                              createPerformer={createPerformer}
                              cancelAddPerformer={cancelAddPerformer}
                              originalPerformer={originalPerformer}
                              cancelEditPerformer={cancelEditPerformer}
                              confirm={confirm} />
                <hr className="card-separator" />
              </React.Fragment>
            ) : null}
            {confirm.type === "Confirm" && getConfirmPageSection(confirmPageSections, "show_details") ? (
              <React.Fragment>
                <ManageShowActivities csrfToken={csrfToken}
                                      currentUser={currentUser}
                                      confirm={confirm}
                                      fetchConfirmShowActivities={fetchConfirmShowActivities}
                                      showActivities={showActivities}
                                      deleteShowActivity={deleteShowActivity}
                                      editShowActivity={editShowActivity}
                                      cancelEditShowActivity={cancelEditShowActivity}
                                      addNewShowActivity={addNewShowActivity}
                                      showActivityNameChanged={showActivityNameChanged}
                                      showActivityStartDateChanged={showActivityStartDateChanged}
                                      showActivityStartTimeChanged={showActivityStartTimeChanged}
                                      showActivityCopy={showActivityCopy}
                                      createShowActivity={createShowActivity}
                                      updateShowActivity={updateShowActivity}
                                      showAdvanceSends={showAdvanceSends}
                                      team={team} />
                <hr className="card-separator" />
              </React.Fragment>
            ) : null}
            {canShowManageOffersSection ? (
              <React.Fragment>
                <ManageOffers csrfToken={csrfToken}
                              currentUser={currentUser}
                              confirm={confirm}
                              modalOffer={modalOffer}
                              defaultOffer={defaultOffer}
                              offerModalIsOpen={offerModalIsOpen}
                              closeOfferModal={closeOfferModal}
                              openOfferModal={openOfferModal}
                              offerTemplates={offerTemplates}
                              venuesOptions={venuesOptions}
                              onManageLineupInit={onManageLineupInit}
                              fetchOfferData={fetchOfferData}
                              refreshPerformer={refreshPerformer}
                              offerPerformer={offerPerformer}
                              setOfferPerformer={setOfferPerformer}
                              acceptOffer={acceptOffer}
                              declineOffer={declineOffer}
                              offers={offers}
                              offerChanged={offerChanged}
                              updateOfferArtist={updateOfferArtist}
                              venueOwnerships={venueOwnerships}
                              refreshConfirm={refreshConfirm}
                              refreshConfirmOffers={refreshConfirmOffers}
                              deleteOffer={deleteOffer}
                              venueSelected={venueSelected}
                              performers={performers}
                              offerForSettlement={offerForSettlement}
                              offerForSettlementChanged={offerForSettlementChanged}
                              confirmPageSection={
                                getConfirmPageSection(confirmPageSections, "offers")
                              }
                              fetchPermissionSet={fetchPermissionSet}
                              updateConfirmPageSection={updateConfirmPageSection}
                              possibleMatchingOffers={possibleMatchingOffers}
                              dismissMatchingOffers={dismissMatchingOffers}
                              acceptMatchingOffer={acceptMatchingOffer}
                              team={team} />
                <hr className="card-separator" />
              </React.Fragment>
            ) : null}
            {team.package.pipeline && getConfirmPageSection(confirmPageSections, "settlements") && offersWithSettlements(offers).length > 0 ? (
              <React.Fragment>
                <ManageSettlements csrfToken={csrfToken}
                                  currentUser={currentUser}
                                  confirm={confirm}
                                  offerChanged={offerChanged}
                                  fetchOfferTotalDue={fetchOfferTotalDue}
                                  offers={offersWithSettlements(offers)}
                                  offerForSettlement={offerForSettlement}
                                  offerForSettlementChanged={offerForSettlementChanged}
                                  confirmPageSection={
                                    getConfirmPageSection(confirmPageSections, "settlements")
                                  }
                                  fetchPermissionSet={fetchPermissionSet}
                                  updateConfirmPageSection={updateConfirmPageSection}
                                  team={team} />
                <hr className="card-separator" />
              </React.Fragment>
            ) : null}
            {team.package.pipeline && getConfirmPageSection(confirmPageSections, "offers") ? (
              <React.Fragment>
                <ManageFinanceItems
                  csrfToken={csrfToken}
                  currentUser={currentUser}
                  team={team}
                  confirm={confirm}
                  performers={performers}
                  financeItems={financeItems}
                  financeItemsChanged={financeItemsChanged}
                  fetchNewFinanceItem={fetchNewFinanceItem}
                  deleteFinanceItem={deleteFinanceItem}
                  financeItemToEdit={financeItemToEdit}
                  financeItemToEditChanged={financeItemToEditChanged}
                  financeItemToEditCanceled={financeItemToEditCanceled}
                  financeItemForForm={financeItemForForm}
                  financeItemForFormChanged={financeItemForFormChanged}
                  financeCategories={financeCategories}
                  financeCategoriesChanged={financeCategoriesChanged}
                  createFinanceItem={createFinanceItem}
                  updateFinanceItem={updateFinanceItem}
                  offers={offersWithSettlements(offers)}
                  offerChanged={offerChanged}
                  updateDeposit={updateDeposit}
                />
                <hr className="card-separator" />
              </React.Fragment>
            ) : null}
            {getConfirmPageSection(confirmPageSections, "notes") && currentUser.can_read_notes ? (
              <React.Fragment>
                <ManageNotes csrfToken={csrfToken}
                             currentUser={currentUser}
                             confirm={confirm}
                             openNote={openNote}
                             openNoteChanged={openNoteChanged}
                             fetchNewNote={fetchNewNote}
                             closeOpenNote={closeOpenNote}
                             openNoteTitleChanged={openNoteTitleChanged}
                             openNoteBodyChanged={openNoteBodyChanged}
                             createNote={createNote}
                             notes={notes}
                             noteTemplates={noteTemplates}
                             noteTemplatesChanged={noteTemplatesChanged}
                             deleteNote={deleteNote}
                             fetchNoteToEdit={fetchNoteToEdit}
                             updateNote={updateNote}
                             openNoteOriginal={openNoteOriginal}
                             openNoteLoading={openNoteLoading}
                             fetchNoteToPreview={fetchNoteToPreview}
                             disableOpenNotePreviewMode={disableOpenNotePreviewMode}
                             fetchPermissionSet={fetchPermissionSet}
                             noteUpdated={noteUpdated}
                             team={team} />
                <hr className="card-separator" />
              </React.Fragment>
            ) : null}
            {confirm.type === "Confirm" && team.package.marketing && getConfirmPageSection(confirmPageSections, "marketing") && currentUser.can_read_posts ? (
              <React.Fragment>
                <MarketingSection csrfToken={csrfToken}
                                  team={team}
                                  currentUser={currentUser}
                                  sendingCustomerIOEmail={sendingCustomerIOEmail}
                                  sendingCustomerIOEmailChanged={sendingCustomerIOEmailChanged}
                                  customerIOEmailPurpose={customerIOEmailPurpose}
                                  customerIOEmailPurposeChanged={customerIOEmailPurposeChanged}
                                  customerIOEmailTemplate={customerIOEmailTemplate}
                                  customerIOEmailTemplateChanged={customerIOEmailTemplateChanged}
                                  createCustomerIOEmail={createCustomerIOEmail}
                                  marketingSectionActiveTab={marketingSectionActiveTab}
                                  marketingSectionActiveTabChanged={marketingSectionActiveTabChanged}
                                  authorizedPages={authorizedPages}
                                  addingNewSocialAccount={addingNewSocialAccount}
                                  addingNewSocialAccountChanged={addingNewSocialAccountChanged}
                                  onepostPublicKey={onepostPublicKey}
                                  twitterProviderId={twitterProviderId}
                                  facebookProviderId={facebookProviderId}
                                  composingPost={composingPost}
                                  composingPostChanged={composingPostChanged}
                                  createPost={createPost}
                                  deletePost={deletePost}
                                  postToEdit={postToEdit}
                                  postToEditChanged={postToEditChanged}
                                  updatePost={updatePost}
                                  posts={posts}
                                  updateConfirmPageSection={updateConfirmPageSection}
                                  fetchPermissionSet={fetchPermissionSet}
                                  socialMarketingBudgetItems={socialMarketingBudgetItems}
                                  traditionalMarketingBudgetItems={traditionalMarketingBudgetItems}
                                  addSocialMarketingBudgetItem={addSocialMarketingBudgetItem}
                                  addTraditionalMarketingBudgetItem={addTraditionalMarketingBudgetItem}
                                  createMarketingBudgetItem={createMarketingBudgetItem}
                                  destroyMarketingBudgetItem={destroyMarketingBudgetItem}
                                  editMarketingBudgetItem={editMarketingBudgetItem}
                                  updateMarketingBudgetItem={updateMarketingBudgetItem}
                                  marketingBudgetItemToEdit={marketingBudgetItemToEdit}
                                  marketingBudgetItemToEditChanged={marketingBudgetItemToEditChanged}
                                  confirmPageSection={
                                    getConfirmPageSection(confirmPageSections, "marketing")
                                  }
                                  confirm={confirm} />
                <hr className="card-separator" />
              </React.Fragment>
            ) : null}
            {team.package.assets && getConfirmPageSection(confirmPageSections, "assets") ? (
              <React.Fragment>
                <FolderViewApp csrfToken={csrfToken}
                                folder={folder}
                                teamRootFolder={teamRootFolder}
                                onFolderChanged={folderChanged}
                                currentUser={currentUser}
                                confirm={confirm}
                                guestTeamMemberships={guestTeamMemberships}
                                promotersWithPermission={promotersWithPermission}
                                team={team} />
                {!confirm.has_manually_uploaded_calendar_event_attachment && !hasManuallyAddedCalendarEventAttachments(getAllCalendarEventAttachments(folder.foldered_items)) ? (
                  <div className="card-body pt-0">
                    <div className="card no-border mb-0" style={{'background': '#f4f9fc'}}>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12 col-md-6 col-lg-4 order-md-2 text-left text-md-center">
                            <i className="fal fa-image"
                                style={{"fontSize": "120px", "color": "rgba(25, 130, 196, .5)"}}></i>
                          </div>
                          <div className="col-12 col-md-6 col-lg-8 order-md-1">
                            <h5>Manage your Assets</h5>
                            <p className="text-muted">
                              Keep track of approved band photos, marketing assets, internal docs and everything else.
                            </p>
                            {currentUser.can_manage_calendar_event_attachments ? (
                              <a href="#"
                                  onClick={
                                    (e) => {
                                      e.preventDefault();
                                      hiddenFileUploadInputEl.click();
                                    }
                                  }
                                  className="btn btn-primary">
                                <strong>Upload Files</strong>
                              </a>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </React.Fragment>
            ) : null}
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 side-by-side-col right">
          {confirm.type === "Confirm" && getConfirmPageSection(confirmPageSections, "links") ? (
            <CopyLinks csrfToken={csrfToken}
                       confirm={confirm}
                       confirmChanged={confirmChanged}
                       updateConfirmWebsite={updateConfirmWebsite}
                       currentUser={currentUser}
                       selfRegisterLink={selfRegisterLink}
                       team={team}
                       showUpgradeLink={showUpgradeLink}
                       showDonationLink={showDonationLink}
                       editingConfirmWebsite={editingConfirmWebsite}
                       editingConfirmWebsiteChanged={editingConfirmWebsiteChanged}
                       editingConfirmWebsiteValue={editingConfirmWebsiteValue}
                       editingConfirmWebsiteValueChanged={editingConfirmWebsiteValueChanged} />
          ) : null}
          {getConfirmPageSection(confirmPageSections, "profit_loss") &&
            team.package.pipeline && currentUser.role?.name === "Administrator" ? (
            <ProfitAndLoss
              csrfToken={csrfToken}
              currentUser={currentUser}
              team={team}
              confirm={confirm}
              profitAndLoss={profitAndLoss}
              fetchProfitAndLoss={fetchProfitAndLoss}
            />
          ) : null}
          {confirm.type === "Confirm" && team.package.ticketing && getConfirmPageSection(confirmPageSections, "tickets") ? (
            !confirm.eventbrite_event_id && currentUser.can_access_opendate_ticketing ? (
              <ManageOpendateTickets csrfToken={csrfToken}
                                     confirm={confirm}
                                     confirmChanged={confirmChanged}
                                     updateConfirmTicketForecast={updateConfirmTicketForecast}
                                     currentUser={currentUser}
                                     ticketTypesByPrice={ticketTypesByPrice}
                                     addOnsByPrice={addOnsByPrice}
                                     showWidgetCodeChanged={showWidgetCodeChanged}
                                     manualTicketCounts={manualTicketCounts}
                                     team={team} />
            ) : (
              <ManageTickets csrfToken={csrfToken}
                             confirm={confirm}
                             currentUser={currentUser}
                             team={team} />
            )
          ) : null}
          {getConfirmPageSection(confirmPageSections, "contacts") ? (
            <ManageContacts csrfToken={csrfToken}
                            currentUser={currentUser}
                            confirm={confirm}
                            calendarEventContacts={calendarEventContacts}
                            createCalendarEventContactFromContactId={createCalendarEventContactFromContactId}
                            deleteCalendarEventContact={deleteCalendarEventContact}
                            addCalendarEventContact={addCalendarEventContact}
                            removeUnsavedCalendarEventContact={removeUnsavedCalendarEventContact}
                            editCalendarEventContact={editCalendarEventContact}
                            cancelEditCalendarEventContact={cancelEditCalendarEventContact}
                            updateCalendarEventContactName={updateCalendarEventContactName}
                            updateCalendarEventContactEmail={updateCalendarEventContactEmail}
                            updateCalendarEventContactPhone={updateCalendarEventContactPhone}
                            updateCalendarEventContactContactType={updateCalendarEventContactContactType}
                            updateCalendarEventContactTitle={updateCalendarEventContactTitle}
                            updateCalendarEventContactCompanyName={updateCalendarEventContactCompanyName}
                            submitCalendarEventContactForm={submitCalendarEventContactForm}
                            editUnsavedCalendarEventContact={editUnsavedCalendarEventContact}
                            originalCalendarEventContact={originalCalendarEventContact}
                            createCalendarEventContactFromAgentId={createCalendarEventContactFromAgentId}
                            updateCalendarEventContact={updateCalendarEventContact}
                            confirmInvitationChanged={confirmInvitationChanged}
                            emailsToInviteChanged={emailsToInviteChanged}
                            confirmInvitationsAndTeamMemberships={confirmInvitationsAndTeamMemberships}
                            team={team} />
          ) : null}
        </div>
      </div>
      <ShareSettingsPopup csrfToken={csrfToken}
                          team={team}
                          confirm={confirm}
                          confirmInvitation={confirmInvitation}
                          confirmInvitationChanged={confirmInvitationChanged}
                          emailsToInvite={emailsToInvite}
                          emailsToInviteChanged={emailsToInviteChanged}
                          guestRole={guestRole}
                          createConfirmInvitations={createConfirmInvitations}
                          confirmInvitationsAndTeamMemberships={confirmInvitationsAndTeamMemberships}
                          confirmInvitationMemoChanged={confirmInvitationMemoChanged}
                          calendarEventContacts={calendarEventContacts}
                          isViewingGuests={isViewingGuests}
                          isViewingGuestsChanged={isViewingGuestsChanged}
                          deleteConfirmInvitation={deleteConfirmInvitation}
                          removeConfirmAccess={removeConfirmAccess}
                          shareSettingsPopupClosed={shareSettingsPopupClosed}
                          currentUser={currentUser} />
      <SetPermissionsPopup csrfToken={csrfToken}
                           team={team}
                           confirm={confirm}
                           permissionSet={permissionSet}
                           permissionSetChanged={permissionSetChanged}
                           permissionRuleEnabledClicked={permissionRuleEnabledClicked}
                           selectAllPermissionRulesClicked={selectAllPermissionRulesClicked}
                           submitPermissionSet={submitPermissionSet}
                           onPermissionSetSaved={onPermissionSetSaved}
                           guestTeamMemberships={guestTeamMemberships}
                           promotersWithPermission={promotersWithPermission}
                           currentUser={currentUser} />
      <ShowAdvanceSendPopup csrfToken={csrfToken}
                            confirm={confirm}
                            currentUser={currentUser}
                            showAdvanceSend={showAdvanceSend}
                            showAdvanceSendChanged={showAdvanceSendChanged}
                            showAdvanceSendToChanged={showAdvanceSendToChanged}
                            showAdvanceSendSubjectChanged={showAdvanceSendSubjectChanged}
                            showAdvanceSendBodyChanged={showAdvanceSendBodyChanged}
                            createShowAdvanceSend={createShowAdvanceSend}
                            showAdvanceSendNoteClicked={showAdvanceSendNoteClicked}
                            notes={notes}
                            team={team} />
      <Popup
        open={showWidgetCode}
        contentStyle={embedPopupStyle}
        closeOnDocumentClick={true}
        className={"popup-modal"}
        onClose={
          () => {
            showWidgetCodeChanged(false);
          }
        }
      >
        <div className="popup-modal-container">
          <div className="row"
               style={{"padding": "32px 24px"}}>
            <div className="col d-flex align-items-center">
              <p className="mb-0"
                 style={{"fontSize": "20px"}}>
                <strong>Embed Ticket widget</strong>
              </p>
            </div>
            <div className="col-xs-auto d-flex align-items-center"
                 style={{"paddingRight": "15px"}}>
              <a href="#"
                 onClick={
                   (e) => {
                     e.preventDefault();
                     showWidgetCodeChanged(false);
                   }
                 }
                 style={{"fontSize": "18px"}}
                 className="text-muted">
                <i className="fal fa-times"></i>
              </a>
            </div>
          </div>
          <hr className="m-0"
              style={{"borderTop": "2px solid #f5f5f5"}} />
          <div className="row"
               style={{"padding": "32px 24px 24px 24px"}}>
            <div className="col">
              <p className="text-dark"
                 style={{"fontSize": "16px"}}>
                Paste this snippet into your code so customers can buy tickets directly from your website.
                <br />
                For embedded checkout to work on your site, you must enable HTTPS/TLS.
              </p>
              <textarea className="form-control rounded-0 text-dark"
                        rows="6"
                        disabled={true}
                        style={{
                          "fontSize": "14px",
                          "padding": "16px",
                          "border": "1px solid #dddddd",
                          "background": "#ececec",
                          "resize": "none"
                        }}
                        defaultValue={widgetCode}>
              </textarea>
              <div className="text-right mt-4">
                <CopyToClipboard text={widgetCode}
                                 onCopy={
                                   () => {
                                     toast.success("Copied to clipboard", {
                                       position: toast.POSITION.TOP_CENTER,
                                       draggable: false,
                                       closeOnClick: false,
                                       autoClose: 5000,
                                       hideProgressBar: true
                                     });
                                   }
                                 }>
                  <a href="#"
                     onClick={
                       (e) => {
                         e.preventDefault();
                       }
                     }
                     className="btn btn-outline-primary small">
                    Copy Code
                  </a>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>
      </Popup>
      {user.can_create_calendar_event ? (
        <Popup
          open={confirmToDup !== null}
          arrow={false}
          position="center center"
          contentStyle={newContentStyle(browser)}
          overlayStyle={{ zIndex: "199" }}
          onClose={() => setConfirmToDup(null)}
          lockScroll={true}
        >
          {close => (
            <div
              className="text-left"
              style={{"overflowY": "auto", "overflowX": "hidden", "maxHeight": "96vh"}}
            >
              {confirmToDup ? (
                <EventForm
                  forDuplication={true}
                  formTitle={"Duplicate Event"}
                  formButtonLabel={"Duplicate"}
                  csrfToken={csrfToken}
                  currentUser={user}
                  team={team}
                  venuesOptions={venuesOptions}
                  venueSelected={confirmToDup.venue}
                  submitEventForm={submitDuplicationForm}
                  onSuccess={url => { window.location.href = url; }}
                  updateSelectedVenue={(options, selected) => {
                    setConfirmToDup({...confirmToDup, venue: options.find(venue => venue.id === selected.value)})
                  }}
                  onClose={() => close()}
                  confirm={confirmToDup}
                  confirmChanged={setConfirmToDup}
                  calendarEventFormClosed={(func) => func()}
                  calendarEventTitle={confirmToDup.title}
                  updateCalendarEventTitle={(title) => setConfirmToDup({...confirmToDup, title: title})}
                  calendarEventArtists={confirmToDup.performers.map(performer => (
                    { label: performer.artistable.name, value: performer.artistable.id }
                  ))}
                  updateCalendarEventArtists={(options, updateName) => {
                    setConfirmToDup({
                      ...confirmToDup,
                      performers: options.map(option => ({ artistable: { id: option.value, name: option.label } }))
                    });
                  }}
                  calendarEventType="Confirm"
                  updateCalendarEventType={() => {}}
                  calendarEventTicketForecast={confirmToDup.ticket_forecast}
                  calendarEventTicketForecastChanged={(forecast) => setConfirmToDup({...confirmToDup, ticket_forecast: forecast})}
                  calendarEventNoteEditorState={confirmToDup.note_state}
                  updateCalendarEventNoteEditorState={(noteState) => setConfirmToDup({...confirmToDup, note_state: noteState})}
                  confirmStartDateUpdated={() => {}}
                  confirmStartTimeUpdated={() => {}}
                  userChangedCalendarEventTitle={true}
                  promoters={promoters}
                  promotersChanged={promotersChanged}
                  selectedPromoter={confirmToDup.promoter}
                  selectedPromoterChanged={promoter => setConfirmToDup({...confirmToDup, promoter: promoter})}
                  buyers={buyers}
                  buyersChanged={buyersChanged}
                  selectedBuyer={confirmToDup.buyer}
                  selectedBuyerChanged={buyer => setConfirmToDup({...confirmToDup, buyer: buyer})}
                  submittingForm={submittingDuplicationForm}
                  calendarEventErrors={confirmToDup?.errors || []}
                  doorsTimeDefault={doorsTimeDefault}
                  eventEndTimeDefault={eventEndTimeDefault}
                  userChangedDoorsTime={userChangedDupDoorTime}
                  userChangedDoorsTimeChanged={setUserChangedDupDoorTime}
                  userChangedEventEndTime={userChangedDupEndTime}
                  userChangedEventEndTimeChanged={setUserChangedDupEndTime}
                  applyTimeDefaults={applyTimeDefaults}
                />
              ) : null}
            </div>
          )}
        </Popup>
      ) : null}
    </div>
  );
};

ConfirmShow.propTypes = {
  browser: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  confirmChanged: PropTypes.func.isRequired,
  refreshConfirm: PropTypes.func.isRequired,
  performers: PropTypes.array,
  deletePerformer: PropTypes.func.isRequired,
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  stagePerformerForUpdate: PropTypes.func.isRequired,
  updatePerformer: PropTypes.func.isRequired,
  updatePerformerActType: PropTypes.func.isRequired,
  performerStartTimeChanged: PropTypes.func.isRequired,
  performerEndTimeChanged: PropTypes.func.isRequired,
  addNewPerformer: PropTypes.func.isRequired,
  setArtistOnNewPerformer: PropTypes.func.isRequired,
  createPerformer: PropTypes.func.isRequired,
  cancelAddPerformer: PropTypes.func.isRequired,
  originalPerformer: PropTypes.object,
  cancelEditPerformer: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  openNote: PropTypes.object,
  openNoteChanged: PropTypes.func.isRequired,
  fetchNewNote: PropTypes.func.isRequired,
  closeOpenNote: PropTypes.func.isRequired,
  openNoteTitleChanged: PropTypes.func.isRequired,
  openNoteBodyChanged: PropTypes.func.isRequired,
  createNote: PropTypes.func.isRequired,
  notes: PropTypes.array,
  noteTemplates: PropTypes.array,
  noteTemplatesChanged: PropTypes.func.isRequired,
  deleteNote: PropTypes.func.isRequired,
  fetchNoteToEdit: PropTypes.func.isRequired,
  updateNote: PropTypes.func.isRequired,
  openNoteOriginal: PropTypes.object,
  openNoteLoading: PropTypes.bool,
  fetchNoteToPreview: PropTypes.func.isRequired,
  disableOpenNotePreviewMode: PropTypes.func.isRequired,
  calendarEventContacts: PropTypes.array,
  createCalendarEventContactFromContactId: PropTypes.func.isRequired,
  deleteCalendarEventContact: PropTypes.func.isRequired,
  addCalendarEventContact: PropTypes.func.isRequired,
  removeUnsavedCalendarEventContact: PropTypes.func.isRequired,
  editCalendarEventContact: PropTypes.func.isRequired,
  cancelEditCalendarEventContact: PropTypes.func.isRequired,
  updateCalendarEventContactName: PropTypes.func.isRequired,
  updateCalendarEventContactEmail: PropTypes.func.isRequired,
  updateCalendarEventContactPhone: PropTypes.func.isRequired,
  updateCalendarEventContactContactType: PropTypes.func.isRequired,
  updateCalendarEventContactTitle: PropTypes.func.isRequired,
  updateCalendarEventContactCompanyName: PropTypes.func.isRequired,
  submitCalendarEventContactForm: PropTypes.func.isRequired,
  editUnsavedCalendarEventContact: PropTypes.func.isRequired,
  originalCalendarEventContact: PropTypes.object,
  createCalendarEventContactFromAgentId: PropTypes.func.isRequired,
  modalOffer: PropTypes.object,
  defaultOffer: PropTypes.object,
  offerModalIsOpen: PropTypes.bool,
  closeOfferModal: PropTypes.func.isRequired,
  openOfferModal: PropTypes.func.isRequired,
  offerTemplates: PropTypes.array,
  venuesOptions: PropTypes.array,
  onManageLineupInit: PropTypes.func.isRequired,
  fetchOfferData: PropTypes.func.isRequired,
  refreshPerformer: PropTypes.func.isRequired,
  offerPerformer: PropTypes.object,
  setOfferPerformer: PropTypes.func.isRequired,
  acceptOffer: PropTypes.func.isRequired,
  declineOffer: PropTypes.func.isRequired,
  offers: PropTypes.array,
  refreshConfirmOffers: PropTypes.func.isRequired,
  deleteOffer: PropTypes.func.isRequired,
  folder: PropTypes.object.isRequired,
  deleteConfirm: PropTypes.func.isRequired,
  cancelConfirm: PropTypes.func.isRequired,
  sendingCustomerIOEmail: PropTypes.bool,
  sendingCustomerIOEmailChanged: PropTypes.func.isRequired,
  customerIOEmailPurpose: PropTypes.string,
  customerIOEmailPurposeChanged: PropTypes.func.isRequired,
  customerIOEmailTemplate: PropTypes.string,
  customerIOEmailTemplateChanged: PropTypes.func.isRequired,
  createCustomerIOEmail: PropTypes.func.isRequired,
  marketingSectionActiveTab: PropTypes.string,
  marketingSectionActiveTabChanged: PropTypes.func.isRequired,
  authorizedPages: PropTypes.array,
  addingNewSocialAccount: PropTypes.bool,
  addingNewSocialAccountChanged: PropTypes.func.isRequired,
  onepostPublicKey: PropTypes.string,
  twitterProviderId: PropTypes.number,
  facebookProviderId: PropTypes.number,
  composingPost: PropTypes.bool,
  composingPostChanged: PropTypes.func.isRequired,
  createPost: PropTypes.func.isRequired,
  posts: PropTypes.array,
  deletePost: PropTypes.func.isRequired,
  postToEdit: PropTypes.object,
  postToEditChanged: PropTypes.func.isRequired,
  updatePost: PropTypes.func.isRequired,
  updateCalendarEventContact: PropTypes.func.isRequired,
  fetchConfirmShowActivities: PropTypes.func.isRequired,
  showActivities: PropTypes.array,
  deleteShowActivity: PropTypes.func.isRequired,
  editShowActivity: PropTypes.func.isRequired,
  cancelEditShowActivity: PropTypes.func.isRequired,
  addNewShowActivity: PropTypes.func.isRequired,
  showActivityNameChanged: PropTypes.func.isRequired,
  showActivityStartDateChanged: PropTypes.func.isRequired,
  showActivityStartTimeChanged: PropTypes.func.isRequired,
  showActivityCopy: PropTypes.object,
  createShowActivity: PropTypes.func.isRequired,
  updateShowActivity: PropTypes.func.isRequired,
  showAdvanceSend: PropTypes.object,
  showAdvanceSendChanged: PropTypes.func.isRequired,
  showAdvanceSendToChanged: PropTypes.func.isRequired,
  showAdvanceSendSubjectChanged: PropTypes.func.isRequired,
  showAdvanceSendBodyChanged: PropTypes.func.isRequired,
  createShowAdvanceSend: PropTypes.func.isRequired,
  showAdvanceSendNoteClicked: PropTypes.func.isRequired,
  venueSelected: PropTypes.object,
  confirmInvitation: PropTypes.object,
  confirmInvitationChanged: PropTypes.func.isRequired,
  confirmInvitationMemoChanged: PropTypes.func.isRequired,
  emailsToInvite: PropTypes.array,
  emailsToInviteChanged: PropTypes.func.isRequired,
  guestRole: PropTypes.object.isRequired,
  createConfirmInvitations: PropTypes.func.isRequired,
  pendingConfirmInvitations: PropTypes.array,
  guestTeamMemberships: PropTypes.array,
  isViewingGuests: PropTypes.bool,
  isViewingGuestsChanged: PropTypes.func.isRequired,
  updateConfirmWebsite: PropTypes.func.isRequired,
  updateConfirmTicketForecast: PropTypes.func.isRequired,
  deleteConfirmInvitation: PropTypes.func.isRequired,
  removeConfirmAccess: PropTypes.func.isRequired,
  shareSettingsPopupClosed: PropTypes.func.isRequired,
  selfRegisterLink: PropTypes.string,
  offerForSettlement: PropTypes.object,
  offerForSettlementChanged: PropTypes.func.isRequired,
  permissionSet: PropTypes.object,
  permissionSetChanged: PropTypes.func.isRequired,
  fetchPermissionSet: PropTypes.func.isRequired,
  permissionRuleEnabledClicked: PropTypes.func.isRequired,
  selectAllPermissionRulesClicked: PropTypes.func.isRequired,
  submitPermissionSet: PropTypes.func.isRequired,
  onPermissionSetSaved: PropTypes.func.isRequired,
  noteUpdated: PropTypes.func.isRequired,
  confirmPageSections: PropTypes.array,
  updateConfirmPageSection: PropTypes.func.isRequired,
  promotersWithPermission: PropTypes.array,
  showAdvanceSends: PropTypes.array,
  socialMarketingBudgetItems: PropTypes.array,
  traditionalMarketingBudgetItems: PropTypes.array,
  addSocialMarketingBudgetItem: PropTypes.func.isRequired,
  addTraditionalMarketingBudgetItem: PropTypes.func.isRequired,
  createMarketingBudgetItem: PropTypes.func.isRequired,
  destroyMarketingBudgetItem: PropTypes.func.isRequired,
  editMarketingBudgetItem: PropTypes.func.isRequired,
  updateMarketingBudgetItem: PropTypes.func.isRequired,
  marketingBudgetItemToEdit: PropTypes.object,
  marketingBudgetItemToEditChanged: PropTypes.func.isRequired,
  financeItems: PropTypes.array,
  financeItemsChanged: PropTypes.func.isRequired,
  financeCategories: PropTypes.array,
  financeCategoriesChanged: PropTypes.func.isRequired,
  fetchNewFinanceItem: PropTypes.func.isRequired,
  deleteFinanceItem: PropTypes.func.isRequired,
  financeItemToEdit: PropTypes.object,
  financeItemToEditChanged: PropTypes.func.isRequired,
  financeItemToEditCanceled: PropTypes.func.isRequired,
  financeItemForForm: PropTypes.object,
  financeItemForFormChanged: PropTypes.func.isRequired,
  createFinanceItem: PropTypes.func.isRequired,
  updateFinanceItem: PropTypes.func.isRequired,
  ticketTypesByPrice: PropTypes.array,
  showWidgetCode: PropTypes.bool,
  showWidgetCodeChanged: PropTypes.func.isRequired,
  addOnsByPrice: PropTypes.array,
  uncancelConfirm: PropTypes.func.isRequired,
  buyers: PropTypes.array,
  buyersChanged: PropTypes.func.isRequired,
  selectedBuyer: PropTypes.object,
  selectedBuyerChanged: PropTypes.func.isRequired,
  promoters: PropTypes.array,
  promotersChanged: PropTypes.func.isRequired,
  selectedPromoter: PropTypes.object,
  selectedPromoterChanged: PropTypes.func.isRequired,
  venueOwnerships: PropTypes.array,
  editingConfirmWebsite: PropTypes.bool,
  editingConfirmWebsiteChanged: PropTypes.func.isRequired,
  editingConfirmWebsiteValue: PropTypes.string,
  editingConfirmWebsiteValueChanged: PropTypes.func.isRequired,
  fetchOfferTotalDue: PropTypes.func.isRequired,
  offerChanged: PropTypes.func.isRequired,
  updateOfferArtist: PropTypes.func.isRequired,
  folderChanged: PropTypes.func.isRequired,
  teamRootFolder: PropTypes.object.isRequired,
  profitAndLoss: PropTypes.object,
  profitAndLossChanged: PropTypes.func.isRequired,
  fetchProfitAndLoss: PropTypes.func.isRequired,
  updateDeposit: PropTypes.func.isRequired,
  mayCancelConfirm: PropTypes.bool,
  mayUncancelConfirm: PropTypes.bool,
  holdGroupDeletion: PropTypes.object,
  holdGroupDeletionChanged: PropTypes.func.isRequired,
  deleteHoldGroup: PropTypes.func.isRequired,
  holdToConfirm: PropTypes.object,
  holdToConfirmChanged: PropTypes.func.isRequired,
  applyTimeDefaults: PropTypes.func.isRequired,
  doorsTimeDefault: PropTypes.object.isRequired,
  eventEndTimeDefault: PropTypes.object.isRequired,
  userChangedDoorsTime: PropTypes.bool,
  userChangedDoorsTimeChanged: PropTypes.func.isRequired,
  userChangedEventEndTime: PropTypes.bool,
  userChangedEventEndTimeChanged: PropTypes.func.isRequired,
  convertHoldGroupToConfirm: PropTypes.func.isRequired,
  isConvertingHoldToConfirm: PropTypes.bool,
  possibleMatchingOffers: PropTypes.array,
  fetchPossibleMatchingOffers: PropTypes.func.isRequired,
  dismissMatchingOffers: PropTypes.func.isRequired,
  acceptMatchingOffer: PropTypes.func.isRequired,
  manualTicketCounts: PropTypes.array,
  showUpgradeLink: PropTypes.bool,
  showDonationLink: PropTypes.bool,
};

export default ConfirmShow;
