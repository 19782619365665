import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

var _ = require('lodash');
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
const axios = require('axios').default;
import { ToastContainer, toast } from 'react-toastify';
import Popup from "reactjs-popup";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import RichEditor from '../../RichEditor/components/RichEditor';
var moment = require('moment');

import { tippy } from '@tippyjs/react';
import Flatpickr from "react-flatpickr";
import DayPicker, { DateUtils } from 'react-day-picker';
import {DebounceInput} from 'react-debounce-input';
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import HoldPositionPicker from './HoldPositionPicker';

import {
  offsetDateForBrowser
} from '../../../shared/timeZoneLogic';

const onDayCreate = (dObj, dStr, fp, dayElem) => {
  let dateStr = dayElem.getAttribute('aria-label');
  let date = new Date(dateStr);
  let tooltipContent = "";
  let showTooltip = false;

  if(typeof fp.eventStart === 'undefined' || typeof fp.eventEnd === 'undefined') {
    return;
  }

  if(date && (fp.isEventStart || fp.isEventEnd)){
    let normalizedEventStart = new Date(fp.eventStart.getFullYear(), fp.eventStart.getMonth(), fp.eventStart.getDate());
    let normalizedEventEnd = new Date(fp.eventEnd.getFullYear(), fp.eventEnd.getMonth(), fp.eventEnd.getDate());

    if(fp.isEventStart && date > normalizedEventEnd) {
      tooltipContent = "Event Start must be on or before Event End";
      showTooltip = true;
    }

    if(fp.isEventEnd && date < normalizedEventStart) {
      tooltipContent = "Event End must be on or after Event Start";
      showTooltip = true;
    }
  }

  if(showTooltip) {
    tippy(dayElem, {
      content: tooltipContent,
      zIndex: 999999
    });
  }
};

const tooltipContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "marginTop": "-10px",
  "padding": "6px 9px",
  "textAlign": "center"
}

const modalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "360px",
  "padding": "15px"
}

const selectStyles = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "transparent",
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  menu: provided => ({ ...provided, zIndex: "9999 !important" })
};

const canSubmitForm = function(calendarEventType, calendarEventTitle, venueSelected, holdGroup, confirm, submittingForm, makeUnavailable, forDuplication){
  var baseConditions = (
    calendarEventTitle
      && calendarEventTitle.length > 0
      && venueSelected
      && !submittingForm
  );

  if(calendarEventType === "HoldGroup"){
    var hasHoldGroupEntry = (holdGroup.hold_group_entries || []).find((holdGroupEntry) => {
      return !holdGroupEntry._destroy;
    });

    var allHoldsHaveAPosition = (holdGroup.hold_group_entries || []).every((holdGroupEntry) => {
      return holdGroupEntry.hold.position;
    });

    var hasAtLeastOneAvailableHoldPosition = (
      !makeUnavailable || (holdGroup.hold_group_entries || []).some(holdGroupEntry => {
          var firstHoldAvailability = (holdGroupEntry.hold.hold_availability || [])[0];
          return firstHoldAvailability && !firstHoldAvailability.is_confirm;
        })
    );

    return (
      baseConditions
        && hasHoldGroupEntry
        && allHoldsHaveAPosition
        && hasAtLeastOneAvailableHoldPosition
    );
  } else {
    if(!forDuplication && holdGroup.id){
      return (
        baseConditions
          && confirm.hold
          && confirm.start_time
          && confirm.door_time
          && confirm.end_time
      );
    } else {
      return (
        baseConditions
          && confirm.start_time
          && confirm.door_time
          && confirm.end_time
      );
    }
  }
}

const canDeleteConfirmOrHoldGroup = function(holdGroup, confirm){
  var holdGroupIsPersisted = (
    holdGroup
      && holdGroup.id
  );

  var confirmIsPersisted = (
    confirm
      && confirm.id
  );

  return (
    holdGroupIsPersisted
      || confirmIsPersisted
  );
}

const debouncedFetchEventTemplates = _.debounce((team, inputValue, callback) => {
  new Promise(resolve => {
    axios.get(`/teams/${team.id}/event_templates`).then(function (response) {
      var newOptions = response.data.map(function(data){
        return { value: data.id, label: data.name };
      });

      callback(newOptions);
    })
  });
}, 250);

const debouncedFetch = _.debounce((inputValue, callback) => {
  new Promise(resolve => {
    axios.get('/artists/search', {
        params: {
          name: inputValue
        }
      })
      .then(function (response) {
        var newOptions = response.data.map(function(data){
          return { value: data.id, label: data.name };
        });

        callback(newOptions);
      })
  });
}, 250);

const debouncedFetchBuyers = _.debounce((team, inputValue, callback) => {
  new Promise(resolve => {
    axios.get(`/teams/${team.id}/companies/autocomplete`, {
      params: {
        name: inputValue
      }
    })
    .then(function (response) {
      var newOptions = response.data;

      if(!newOptions.map(option => option.label).includes(inputValue)) {
        newOptions.unshift({
          value: inputValue,
          label: `Add "${inputValue}"`,
          name: inputValue
        })
      }

      callback(newOptions);
    })
  });
}, 250);

const debouncedFetchPromoters = _.debounce((team, inputValue, callback) => {
  new Promise(resolve => {
    axios.get(`/teams/${team.id}/promoters/autocomplete`, {
      params: {
        name: inputValue
      }
    })
    .then(function (response) {
      var newOptions = response.data;

      if(!newOptions.map(option => option.label).includes(inputValue)) {
        newOptions.unshift({
          value: inputValue,
          label: `Add "${inputValue}"`,
          name: inputValue
        })
      }

      callback(newOptions);
    })
  });
}, 250);

const createBuyer = (team, buyer, successCallback, errorCallback) => {
  axios.post(`/teams/${team.id}/companies`, {
    company: {
      name: buyer
    }
  })
  .then(response => successCallback(response.data))
  .catch(errorCallback)
};

const createPromoter = (team, promoter, successCallback, errorCallback) => {
  axios.post(`/teams/${team.id}/promoters`, {
    promoter: {
      name: promoter
    }
  })
  .then(response => successCallback(response.data))
  // .catch(errorCallback)
};

const flatPickrFormat = "n/j/y at h:iK"

const EventForm = ({
  formTitle,
  formButtonLabel,
  csrfToken,
  team,
  venuesOptions,
  venueSelected,
  submitEventForm,
  updateSelectedVenue,
  calendarEventFormClosed,
  deleteEvent,
  holdGroup,
  confirm,
  confirmChanged,
  calendarEventTitle,
  updateCalendarEventTitle,
  calendarEventArtists,
  updateCalendarEventArtists,
  calendarEventType,
  updateCalendarEventType,
  calendarEventTicketForecast,
  calendarEventTicketForecastChanged,
  calendarEventNoteEditorState,
  updateCalendarEventNoteEditorState,
  calendarEventErrors,
  userChangedCalendarEventTitle,
  holdGroupCalendarDateClicked,
  holdGroupDates,
  holdGroupHoldPositionUpdated,
  holdGroupHoldRemoved,
  confirmStartDateUpdated,
  confirmStartTimeUpdated,
  fetchHoldAvailability,
  confirmHoldUpdated,
  calendarRef,
  calendarStartDate,
  onClose,
  onSuccess,
  selectedPromoter,
  selectedPromoterChanged,
  buyers,
  buyersChanged,
  promoters,
  promotersChanged,
  selectedBuyer,
  selectedBuyerChanged,
  currentUser,
  holdGroupDeletion,
  holdGroupDeletionChanged,
  holdGroupDeletionReasonChanged,
  holdGroupDeletionExplanationChanged,
  holdGroupDeletionFollowUpChanged,
  holdGroupDeletionFollowUpAtChanged,
  deleteHoldGroup,
  onVenueChange,
  submittingForm,
  doorsTimeDefault,
  eventEndTimeDefault,
  userChangedDoorsTime,
  userChangedDoorsTimeChanged,
  userChangedEventEndTime,
  userChangedEventEndTimeChanged,
  applyTimeDefaults,
  holdGroupChanged,
  forDuplication,
}) => {
  const [makeUnavailable, setMakeUnavailable] = useState(false);
  const [startTimeValue, setStartTimeValue] = useState(null);

  const canEnableDate = (date) => {
    const dateString = date.toDateString();

    var datesToEnable = holdGroup.hold_group_entries.map(entry => {
      return entry.hold.start_time.toDateString();
    });

    if(typeof confirm.start_time !== 'undefined') {
      datesToEnable.push(confirm.start_time)
    }

    return datesToEnable.includes(dateString);
  }

  const calendarMonthChanged = (_selectedDates, _dateStr, instance) => {
    const firstOfMonth = new Date(
      instance.currentYear,
      instance.currentMonth,
      1,
    ).setHours(12);

    setStartTimeValue(firstOfMonth);
    calendarRef.getApi().gotoDate(firstOfMonth);
  }

  useEffect(() => {
    setStartTimeValue(confirm.start_time);
    applyTimeDefaults(
      confirm,
      doorsTimeDefault,
      eventEndTimeDefault,
      !userChangedDoorsTime,
      !userChangedEventEndTime,
      updatedConfirm => confirmChanged(updatedConfirm)
    );
  }, [confirm.start_time])

  useEffect(() => {
    if (holdGroup && holdGroup.hold_group_entries && holdGroup.hold_group_entries.length === 1) {
      const holdStartTime = holdGroup.hold_group_entries[0].hold.start_time;
      setStartTimeValue(holdStartTime.setHours(12));
    } else {
      setStartTimeValue(null);
    }
  }, [holdGroup])

  return(
    <div className="event-form-container">
      <ToastContainer />
      <div className="row py-2">
        <div className="col-12">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <h5><strong>{ formTitle }</strong></h5>
              </div>
              <div className="col text-right">
                <a href="#"
                   className="text-muted"
                   onClick={
                     (e) => {
                       e.preventDefault();
                       calendarEventFormClosed(onClose);
                     }
                   } >
                  <i className="fa fa-times"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <hr />
        </div>
        <div className="col-12">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <form onSubmit={
                  (e) => {
                    e.preventDefault();

                    if(canSubmitForm(calendarEventType, calendarEventTitle, venueSelected, holdGroup, confirm, submittingForm, makeUnavailable, forDuplication)){
                      var holdGroupCopy = {...holdGroup};

                      if(makeUnavailable && holdGroupCopy && holdGroupCopy.hold_group_entries){
                        var filteredHoldGroupEntries = holdGroupCopy.hold_group_entries.filter((holdGroupEntry) => {
                          var firstHoldAvailability = (holdGroupEntry.hold.hold_availability || [])[0];
                          return firstHoldAvailability && !firstHoldAvailability.is_confirm;
                        });

                        holdGroupCopy = {...holdGroupCopy, hold_group_entries: filteredHoldGroupEntries};
                      }

                      submitEventForm(csrfToken, team, () => {calendarEventFormClosed(onClose)}, calendarEventType, calendarEventArtists, calendarEventTitle, venueSelected, calendarEventTicketForecast, calendarEventNoteEditorState, confirm, holdGroupCopy, onSuccess, selectedPromoter, selectedBuyer);
                    }
                  }
                }>
                  {!forDuplication && currentUser.can_create_calendar_event ? (
                    <div className="form-group">
                      <div className="btn-group btn-block" role="group" aria-label="Event Type">
                        <button type="button"
                                onClick={
                                  (e) => {
                                    e.preventDefault();
                                    updateCalendarEventType("HoldGroup");
                                  }
                                }
                                className={"btn " + (calendarEventType === "HoldGroup" ? "btn-primary" : "btn-external")}>
                          <strong>Hold</strong>
                        </button>
                        <button type="button"
                                onClick={
                                  (e) => {
                                    e.preventDefault();
                                    updateCalendarEventType("Confirm");

                                    if(holdGroup && holdGroup.hold_position_context && holdGroup.hold_position_context.start_time){
                                      var defaultDate = new Date(holdGroup.hold_position_context.start_time);
                                      confirmChanged({...confirm, start_time: defaultDate});
                                    }
                                  }
                                }
                                className={"btn " + (calendarEventType === "Confirm" ? "btn-primary" : "btn-external")}>
                          <strong>Confirm</strong>
                        </button>
                      </div>
                    </div>
                  ) : null}
                  {!forDuplication ? (
                    <div className="form-group">
                      <label htmlFor="new-event-template" className="text-muted">
                        Template
                        <span className="text-optional pl-2">
                          (optional)
                        </span>
                      </label>
                      <AsyncSelect
                        cacheOptions
                        styles={selectStyles}
                        menuPortalTarget={!forDuplication ? document.querySelector('body') : null}
                        defaultOptions
                        id="new-event-template"
                        value={confirm.event_template ? confirm.event_template : null}
                        loadOptions={(inputValue, callback) => debouncedFetchEventTemplates(team, inputValue, callback)}
                        onChange={(option) => {
                          confirmChanged({...confirm, event_template: { value: option.value, label: option.label }})
                          holdGroupChanged({...holdGroup, event_template: { value: option.value, label: option.label }})
                        }}
                      />
                    </div>
                  ) : null}
                  <div className="form-group">
                    <label htmlFor="new-event-artists" className="text-muted">
                      Artist(s)
                      <span className="text-optional pl-2">
                        (optional)
                      </span>
                    </label>
                    <AsyncSelect cacheOptions
                                 styles={selectStyles}
                                 menuPortalTarget={!forDuplication ? document.querySelector('body') : null}
                                 defaultOptions
                                 isMulti
                                 id="new-event-artists"
                                 onChange={(options) => { updateCalendarEventArtists(options || [], userChangedCalendarEventTitle) }}
                                 value={ calendarEventArtists }
                                 loadOptions={(inputValue, callback) => debouncedFetch(inputValue, callback)} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="new-event-title" className="text-muted">Event Name</label>
                    <input type="text"
                           className="form-control"
                           value={calendarEventTitle || ""}
                           onChange={(e) => updateCalendarEventTitle(e.target.value)}
                           id="new-event-title" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="new-event-venue" className="text-muted">Venue</label>
                    <Select options={
                              venuesOptions.map(function(venue){
                                return { value: venue.id, label: venue.name }
                              })
                            }
                            id="new-event-venue"
                            styles={selectStyles}
                            menuPortalTarget={!forDuplication ? document.querySelector('body') : null}
                            value={
                              (venueSelected ? (
                                { value: venueSelected.id, label: venueSelected.name }
                              ) : null)
                            }
                            onChange={
                              (selected) => {
                                updateSelectedVenue(venuesOptions, selected);

                                if(typeof(onVenueChange) === "function"){
                                  onVenueChange(selected);
                                }
                              }
                            } />
                  </div>
                  {currentUser.role.name !== "Promoter" ? (
                    <div className="form-group">
                      <label htmlFor="new-event-promoter"
                             className="text-muted">
                        Promoter
                      </label>
                      <AsyncSelect
                        placeholder={"Select or create a promoter"}
                        styles={selectStyles}
                        menuPortalTarget={!forDuplication ? document.querySelector('body') : null}
                        isClearable={true}
                        defaultOptions={promoters}
                        id="edit-event-promoter"
                        value={promoters.find(promoter => promoter.value === selectedPromoter?.value)}
                        loadOptions={(inputValue, callback) => debouncedFetchPromoters(team, inputValue, callback)}
                        onChange={
                          (option) => {
                            if(option === null) {
                              selectedPromoterChanged({});
                            } else if(promoters.find(promoter => promoter.value === option.value)) {
                              selectedPromoterChanged(option);
                            } else {
                              createPromoter(team, option.name,
                                (newPromoter) => { // Success callback
                                  var newOption = { label: newPromoter.name, value: newPromoter.id };
                                  promotersChanged([...promoters, newOption]);
                                  selectedPromoterChanged(newOption);
                                  toast.success(`Promoter "${newPromoter.name}" was created`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    draggable: false,
                                    closeOnClick: false,
                                    autoClose: 5000,
                                    hideProgressBar: true
                                  });
                                },
                                (error) => { // Error callback
                                  selectedPromoterChanged(null);
                                  toast.error(`Could not create promoter "${option.name}"`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    draggable: false,
                                    closeOnClick: false,
                                    autoClose: 5000,
                                    hideProgressBar: true
                                  });
                                });
                            }
                          }
                        }
                      />
                    </div>
                  ) : null}
                  <div className="form-group">
                    <label htmlFor="new-event-buyer"
                           className="text-muted">
                      Buyer
                    </label>
                    <AsyncSelect placeholder={"Select or create a buyer"}
                                 styles={selectStyles}
                                 menuPortalTarget={!forDuplication ? document.querySelector('body') : null}
                                 isClearable={true}
                                 defaultOptions={buyers.map((buyer) => { return {label: buyer.label, value: buyer.value} })}
                                 id="new-event-buyer"
                                 value={_.isEmpty(selectedBuyer) ? null : selectedBuyer}
                                 loadOptions={(inputValue, callback) => debouncedFetchBuyers(team, inputValue, callback)}
                                 onChange={
                                   (option) => {
                                     if(option === null) {
                                       selectedBuyerChanged({});
                                     } else if(buyers.map(buyer => buyer.label).includes(option.label)) {
                                       selectedBuyerChanged(option);
                                     } else {
                                       createBuyer(team, option.name,
                                         (newBuyer) => { // Success callback
                                           var newOption = { label: newBuyer.name, value: newBuyer.id };
                                           buyersChanged([...buyers, newOption]);
                                           selectedBuyerChanged(newOption);
                                           toast.success(`Company "${newBuyer.name}" was created`, {
                                             position: toast.POSITION.TOP_CENTER,
                                             draggable: false,
                                             closeOnClick: false,
                                             autoClose: 5000,
                                             hideProgressBar: true
                                           });
                                         },
                                         () => { // Error callback
                                           selectedBuyerChanged({});
                                           toast.error(`Could not create company "${option.name}"`, {
                                             position: toast.POSITION.TOP_CENTER,
                                             draggable: false,
                                             closeOnClick: false,
                                             autoClose: 5000,
                                             hideProgressBar: true
                                           });
                                         });
                                     }
                                   }
                                 } />
                  </div>
                  {calendarEventType === "HoldGroup" ? (
                    <HoldPositionPicker csrfToken={csrfToken}
                                        team={team}
                                        venueSelected={venueSelected}
                                        holdGroup={holdGroup}
                                        holdGroupCalendarDateClicked={holdGroupCalendarDateClicked}
                                        holdGroupDates={holdGroupDates}
                                        makeUnavailable={makeUnavailable}
                                        setMakeUnavailable={setMakeUnavailable}
                                        holdGroupHoldPositionUpdated={holdGroupHoldPositionUpdated}
                                        holdGroupHoldRemoved={holdGroupHoldRemoved}
                                        fetchHoldAvailability={fetchHoldAvailability}
                                        calendarRef={calendarRef}
                                        calendarStartDate={calendarStartDate} />
                  ) : null}
                  {calendarEventType === "Confirm" ? (
                    <React.Fragment>
                      <div className="form-group">
                        <label htmlFor="start_date_and_time" className="text-muted">
                          Event Start Date/Time
                        </label>
                        <div className="flatpickr-date-input px-2 d-flex align-items-center">
                          <div className="pr-2" style={{"fontSize": "18px"}}>
                            <i className="far fa-calendar"></i>
                          </div>
                          {!forDuplication && holdGroup.id ? (
                            <Flatpickr
                              options={{
                                enableTime: true,
                                dateFormat: flatPickrFormat,
                                enable: [canEnableDate]
                              }}
                              placeholder={"Select date"}
                              value={confirm.start_time}
                              onClose={dates => {
                                confirmChanged({...confirm, start_time: dates[0]});
                              }}
                            />
                          ) : (
                            <Flatpickr
                              options={{
                                enableTime: true,
                                dateFormat: flatPickrFormat,
                                minDate: offsetDateForBrowser(new Date()),
                                maxDate: offsetDateForBrowser(confirm.end_time || null),
                                onDayCreate: (dObj, dStr, fp, dayElem) => {
                                  fp = {
                                    ...fp,
                                    isEventStart: true,
                                    eventStart: offsetDateForBrowser(confirm.start_time),
                                    eventEnd: offsetDateForBrowser(confirm.end_time)
                                  }
                                  onDayCreate(dObj, dStr, fp, dayElem);
                                }
                              }}
                              placeholder={"Select date"}
                              value={startTimeValue}
                              onOpen={() => {
                                if (!startTimeValue) {
                                  setStartTimeValue(calendarStartDate.setHours(12));
                                }
                              }}
                              onClose={dates => {
                                confirmChanged({...confirm, start_time: dates[0]});
                              }}
                              onMonthChange={calendarMonthChanged}
                              onYearChange={calendarMonthChanged}
                            />
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="doors_date_and_time" className="text-muted">
                          Doors Date/Time
                        </label>
                        <div className="flatpickr-date-input px-2 d-flex align-items-center">
                          <div className="pr-2" style={{"fontSize": "18px"}}>
                            <i className="far fa-calendar"></i>
                          </div>
                          {!forDuplication && holdGroup.id ? (
                            <Flatpickr
                              options={{
                                enableTime: true,
                                dateFormat: flatPickrFormat,
                                enable: [canEnableDate]
                              }}
                              placeholder={"Select date"}
                              value={confirm.door_time}
                              onClose={dates => {
                                userChangedDoorsTimeChanged(true);
                                confirmChanged({...confirm, door_time: dates[0]});
                              }}
                            />
                          ) : (
                            <Flatpickr
                              options={{
                                enableTime: true,
                                dateFormat: flatPickrFormat,
                                minDate: offsetDateForBrowser(new Date())
                              }}
                              placeholder={"Select date"}
                              value={confirm.door_time}
                              onClose={dates => {
                                userChangedDoorsTimeChanged(true);
                                confirmChanged({...confirm, door_time: dates[0]});
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="end_date_and_time" className="text-muted">
                          Event End Date/Time
                        </label>
                        <div className="flatpickr-date-input px-2 d-flex align-items-center">
                          <div className="pr-2" style={{"fontSize": "18px"}}>
                            <i className="far fa-calendar"></i>
                          </div>
                          {!forDuplication && holdGroup.id ? (
                            <Flatpickr
                              options={{
                                enableTime: true,
                                dateFormat: flatPickrFormat,
                                enable: [canEnableDate]
                              }}
                              placeholder={"Select date"}
                              value={confirm.end_time}
                              onClose={dates => {
                                userChangedEventEndTimeChanged(true);
                                confirmChanged({...confirm, end_time: dates[0]});
                              }}
                            />
                          ) : (
                            <Flatpickr
                              options={{
                                enableTime: true,
                                dateFormat: flatPickrFormat,
                                minDate: offsetDateForBrowser(confirm.start_time || new Date()),
                                onDayCreate: (dObj, dStr, fp, dayElem) => {
                                  fp = {
                                    ...fp,
                                    isEventEnd: true,
                                    eventStart: offsetDateForBrowser(confirm.start_time),
                                    eventEnd: offsetDateForBrowser(confirm.end_time)
                                  }
                                  onDayCreate(dObj, dStr, fp, dayElem);
                                }
                              }}
                              placeholder={"Select date"}
                              value={confirm.end_time}
                              onClose={dates => {
                                userChangedEventEndTimeChanged(true);
                                confirmChanged({...confirm, end_time: dates[0]});
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  ) : null}
                  <div className="form-group pb-2">
                    <label className="text-muted">
                      Ticket Forecast
                    </label>
                    <MaskedInput className="form-control"
                                 style={{"fontSize": "14px"}}
                                 value={calendarEventTicketForecast || ""}
                                 onChange={e => {
                                   calendarEventTicketForecastChanged(parseInt(e.target.value));
                                 }}
                                 mask={createNumberMask({
                                   prefix: "",
                                   decimalLimit: 0,
                                   includeThousandsSeparator: false
                                 })} />
                  </div>
                  <div className="form-group pb-2">
                    <label htmlFor="new-event-notes" className="text-muted">
                      Notes
                      <span className="text-optional pl-2">
                        (optional)
                      </span>
                    </label>
                    <RichEditor
                      editorState={calendarEventNoteEditorState}
                      onEditorStateChange={
                        (editorState) => {
                          updateCalendarEventNoteEditorState(editorState);
                        }
                      } />
                  </div>
                  {calendarEventErrors.length > 0 ? (
                    <div className="form-group">
                      <div className="p-3 mb-2 bg-danger text-white rounded">
                        <ul className="mb-0">
                          {calendarEventErrors.map((error, key) =>
                            <li key={key}>{error}</li>
                          )}
                        </ul>
                      </div>
                    </div>
                  ) : null}
                  <div className="row">
                    {!forDuplication && canDeleteConfirmOrHoldGroup(holdGroup, confirm) ? (
                      <div className="col-12 col-md-3 pr-md-1">
                        <Popup
                          trigger={open => (
                            <a href="#"
                               onClick={
                                 (e) => {
                                   e.preventDefault();

                                   // WARNING: This no longer works with Confirms
                                   // (window.confirm('Are you sure?')
                                   //   && deleteEvent(csrfToken, team, () => {calendarEventFormClosed(onClose)}, holdGroup, confirm));
                                   holdGroupDeletionChanged({hold_group_id: holdGroup.id});
                                 }
                               }
                               className="btn btn-external btn-block my-1 my-md-0">
                              <i className="fa fa-trash"></i>
                            </a>
                          )}
                          on={["hover"]}
                          contentStyle={tooltipContentStyle}
                          position="top center"
                          closeOnDocumentClick
                        >
                          <span>Delete Event</span>
                        </Popup>
                      </div>
                    ) : null}
                    <div className={!forDuplication && canDeleteConfirmOrHoldGroup(holdGroup, confirm) ? "col-12 col-md-9 pl-md-1" : "col-12"}>
                      <button type="submit"
                              disabled={!canSubmitForm(calendarEventType, calendarEventTitle, venueSelected, holdGroup, confirm, submittingForm, makeUnavailable, forDuplication)}
                              className="btn btn-primary btn-block">
                        {submittingForm ? (
                          <React.Fragment>
                            <img src="/uploading-loading.gif"
                                 className="mr-2"
                                 style={{width: "16px"}} />
                            <strong>{ formButtonLabel }</strong>
                          </React.Fragment>
                        ) : (
                          <strong>{ formButtonLabel }</strong>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

EventForm.propTypes = {
  formTitle: PropTypes.string.isRequired,
  formButtonLabel: PropTypes.string.isRequired,
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  venuesOptions: PropTypes.array,
  venueSelected: PropTypes.object,
  submitEventForm: PropTypes.func.isRequired,
  updateSelectedVenue: PropTypes.func.isRequired,
  calendarEventFormClosed: PropTypes.func.isRequired,
  deleteEvent: PropTypes.func,
  holdGroup: PropTypes.object,
  confirm: PropTypes.object,
  confirmChanged: PropTypes.func,
  calendarEventTitle: PropTypes.string,
  updateCalendarEventTitle: PropTypes.func.isRequired,
  calendarEventArtists: PropTypes.array,
  updateCalendarEventArtists: PropTypes.func.isRequired,
  calendarEventType: PropTypes.string,
  updateCalendarEventType: PropTypes.func.isRequired,
  calendarEventTicketForecast: PropTypes.number,
  calendarEventTicketForecastChanged: PropTypes.func.isRequired,
  calendarEventNoteEditorState: PropTypes.object,
  updateCalendarEventNoteEditorState: PropTypes.func.isRequired,
  calendarEventErrors: PropTypes.array,
  userChangedCalendarEventTitle: PropTypes.bool,
  holdGroupCalendarDateClicked: PropTypes.func,
  holdGroupDates: PropTypes.array,
  holdGroupHoldPositionUpdated: PropTypes.func,
  holdGroupHoldRemoved: PropTypes.func,
  confirmStartDateUpdated: PropTypes.func.isRequired,
  confirmStartTimeUpdated: PropTypes.func.isRequired,
  fetchHoldAvailability: PropTypes.func,
  confirmHoldUpdated: PropTypes.func,
  calendarRef: PropTypes.object,
  calendarStartDate: PropTypes.instanceOf(Date),
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  selectedPromoter: PropTypes.object,
  selectedPromoterChanged: PropTypes.func.isRequired,
  buyers: PropTypes.array,
  buyersChanged: PropTypes.func.isRequired,
  promoters: PropTypes.array,
  promotersChanged: PropTypes.func.isRequired,
  selectedBuyer: PropTypes.object,
  selectedBuyerChanged: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  holdGroupDeletion: PropTypes.object,
  holdGroupDeletionChanged: PropTypes.func,
  holdGroupDeletionReasonChanged: PropTypes.func,
  holdGroupDeletionExplanationChanged: PropTypes.func,
  holdGroupDeletionFollowUpChanged: PropTypes.func,
  holdGroupDeletionFollowUpAtChanged: PropTypes.func,
  deleteHoldGroup: PropTypes.func,
  onVenueChange: PropTypes.func,
  submittingForm: PropTypes.bool,
  doorsTimeDefault: PropTypes.object.isRequired,
  eventEndTimeDefault: PropTypes.object.isRequired,
  userChangedDoorsTime: PropTypes.bool,
  userChangedDoorsTimeChanged: PropTypes.func,
  userChangedEventEndTime: PropTypes.bool,
  userChangedEventEndTimeChanged: PropTypes.func,
  applyTimeDefaults: PropTypes.func.isRequired,
  holdGroupChanged: PropTypes.func,
  forDuplication: PropTypes.bool
};

export default EventForm;
