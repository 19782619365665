import ReactOnRails from 'react-on-rails';
import OnepostUI from "onepost-ui";

import AddConfirmToCalendar from '../bundles/AddConfirmToCalendar/components/AddConfirmToCalendar';
import AddOutreachApp from '../bundles/AddOutreach/startup/AddOutreachApp';
import ActivityFeedApp from '../bundles/ActivityFeed/startup/ActivityFeedApp';
import ArtistRepresentationApp from '../bundles/ArtistRepresentation/startup/ArtistRepresentationApp';
import AgentsTableApp from '../bundles/AgentsTable/startup/AgentsTableApp';
import BulkRefundApp from '../bundles/BulkRefund/startup/BulkRefundApp';
import CalendarViewApp from '../bundles/CalendarView/startup/CalendarViewApp';
import CapacityBarChart from '../bundles/CapacityBarChart/components/CapacityBarChart';
import ConfirmAttendeesApp from '../bundles/ConfirmAttendees/startup/ConfirmAttendeesApp';
import ConfirmDashboardApp from '../bundles/ConfirmDashboard/startup/ConfirmDashboardApp';
import ConfirmShowApp from '../bundles/ConfirmShow/startup/ConfirmShowApp';
import ConfirmTicketingApp from '../bundles/ConfirmTicketing/startup/ConfirmTicketingApp';
import DataTable from '../bundles/DataTable/components/DataTable';
import DataTableWithSidebarViewApp from '../bundles/DataTableWithSidebarView/startup/DataTableWithSidebarViewApp';
import EmailNotificationSettingsApp from '../bundles/EmailNotificationSettings/startup/EmailNotificationSettingsApp';
import EventsAssetCenterApp from '../bundles/EventsAssetCenter/startup/EventsAssetCenterApp';
import FanDashboardApp from '../bundles/FanDashboard/startup/FanDashboardApp';
import FanUpcomingEventsApp from '../bundles/FanUpcomingEvents/startup/FanUpcomingEventsApp';
import FollowUpManagerApp from '../bundles/FollowUpManager/startup/FollowUpManagerApp';
import GlobalAssetCenterApp from '../bundles/GlobalAssetCenter/startup/GlobalAssetCenterApp';
import InboundSubmissionsApp from '../bundles/InboundSubmissions/startup/InboundSubmissionsApp';
import LinkOfferModalApp from '../bundles/LinkOfferModal/startup/LinkOfferModalApp';
import MapWithMarker from '../bundles/MapWithMarker/components/MapWithMarker';
import ActionContainer from '../bundles/Marketing/Campaigns/Actions/ActionContainer';
import CampaignsContainer from '../bundles/Marketing/Campaigns/CampaignsContainer';
import CampaignContainer from '../bundles/Marketing/Campaigns/CampaignContainer';
import SegmentsContainer from '../bundles/Marketing/Segments/SegmentsContainer';
import SegmentContainer from '../bundles/Marketing/Segments/SegmentContainer';
import SegmentNewContainer from '../bundles/Marketing/Segments/SegmentNewContainer';
import BroadcastIndexContainer from '../bundles/Marketing/Broadcasts/BroadcastIndexContainer';
import BroadcastShowContainer from '../bundles/Marketing/Broadcasts/BroadcastShowContainer';
import BroadcastNewContainer from '../bundles/Marketing/Broadcasts/BroadcastNewContainer';
import BroadcastEditContainer from '../bundles/Marketing/Broadcasts/BroadcastEditContainer';
import MarketingSettingsIndexContainer from '../bundles/Marketing/MarketingSettings/MarketingSettingsIndexContainer';
import MailkickSubscriptionIndexContainer from '../bundles/Marketing/MailkickSubscriptions/MailkickSubscriptionsIndexContainer';
import BeefreeAddonsIndexContainer from '../bundles/Marketing/BeefreeTemplates/BeefreeAddonIndexContainer';
import AhoyMessageShowContainer from '../bundles/Marketing/AhoyMessages/AhoyMessageShowContainer';
import OfferEditUIApp from '../bundles/OfferEditUI/startup/OfferEditUIApp';
import OfferManagerApp from '../bundles/OfferManager/startup/OfferManagerApp';
import OfferV2PreviewApp from '../bundles/OfferV2Preview/startup/OfferV2PreviewApp';
import OnepostComposer from '../bundles/OnepostComposer/components/OnepostComposer';
import OutreachManagerApp from '../bundles/OutreachManager/startup/OutreachManagerApp';
import PipelineEventsSlidingPaneApp from '../bundles/PipelineEventsSlidingPane/startup/PipelineEventsSlidingPaneApp';
import PostedPostsApp from '../bundles/PostedPosts/startup/PostedPostsApp';
import ProfitAndLossApp from '../bundles/ProfitAndLoss/startup/ProfitAndLossApp';
import PushNotificationsApp from '../bundles/PushNotifications/startup/PushNotificationsApp';
import ReadMore from '../bundles/ReadMore/components/ReadMore';
import RecommendationsTableApp from '../bundles/RecommendationsTable/startup/RecommendationsTableApp';
import RefundSettingsApp from '../bundles/RefundSettings/startup/RefundSettingsApp';
import ScheduledPostsApp from '../bundles/ScheduledPosts/startup/ScheduledPostsApp';
import SemiCircle from '../bundles/SemiCircle/components/SemiCircle';
import SettlementApp from '../bundles/Settlement/startup/SettlementApp';
import SocialManagerApp from '../bundles/SocialManager/startup/SocialManagerApp';
import TableViewApp from '../bundles/TableView/startup/TableViewApp';
import TagManagerApp from '../bundles/TagManager/startup/TagManagerApp';
import ContainerlessTableViewApp from '../bundles/TableView/startup/ContainerlessTableViewApp';
import TeamManagerApp from '../bundles/TeamManager/startup/TeamManagerApp';
import TextMessagesApp from '../bundles/TextMessages/startup/TextMessagesApp';
import TicketingSettingsApp from '../bundles/TicketingSettings/startup/TicketingSettingsApp';
import TicketingSystemsApp from '../bundles/TicketingSystems/startup/TicketingSystemsApp';
import ToolTip from '../bundles/ToolTip/components/ToolTip';
import UserMenuApp from '../bundles/UserMenu/startup/UserMenuApp';
import UserSettingsApp from '../bundles/UserSettings/startup/UserSettingsApp';
import VenueManagerApp from '../bundles/VenueManager/startup/VenueManagerApp';
import WebOrderFormApp from '../bundles/WebOrderForm/startup/WebOrderFormApp';
import OfferV2ModalApp from '../bundles/OfferV2Modal/startup/OfferV2ModalApp'
import ArtistsSearchApp from '../bundles/ArtistsSearch/startup/ArtistsSearchApp';
import GlobalArtistsSearchApp from '../bundles/GlobalArtistsSearch/startup/GlobalArtistsSearchApp';
import ArtistEventLocalShowApp from '../bundles/ArtistEventLocalShow/startup/ArtistEventLocalShowApp';
import ArtistPipelinesApp from '../bundles/ArtistPipelines/startup/ArtistPipelinesApp';
import SimilarArtistsApp from '../bundles/SimilarArtists/startup/SimilarArtistsApp';
import ShowActivitySettingsApp from '../bundles/ShowActivitySettings/startup/ShowActivitySettingsApp';
import UniversalPromoCodeManagerApp from '../bundles/UniversalPromoCodeManager/startup/UniversalPromoCodeManagerApp';
import ArtistDetailsApp from '../bundles/ArtistDetails/startup/ArtistDetailsApp';
import PaymentDisputeManagerApp from '../bundles/PaymentDisputeManager/startup/PaymentDisputeManagerApp';
import PayoutManagerApp from '../bundles/PayoutManager/startup/PayoutManagerApp';
import FinanceSettingsManagerApp from '../bundles/FinanceSettingsManager/startup/FinanceSettingsManagerApp';

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  AddConfirmToCalendar,
  AddOutreachApp,
  ArtistRepresentationApp,
  OfferV2ModalApp,
  ActivityFeedApp,
  AgentsTableApp,
  BulkRefundApp,
  CalendarViewApp,
  CapacityBarChart,
  ConfirmAttendeesApp,
  ConfirmDashboardApp,
  ConfirmShowApp,
  ConfirmTicketingApp,
  DataTable,
  DataTableWithSidebarViewApp,
  EmailNotificationSettingsApp,
  EventsAssetCenterApp,
  FanDashboardApp,
  FanUpcomingEventsApp,
  FollowUpManagerApp,
  GlobalAssetCenterApp,
  InboundSubmissionsApp,
  LinkOfferModalApp,
  OfferEditUIApp,
  MapWithMarker,
  ActionContainer,
  CampaignsContainer,
  CampaignContainer,
  SegmentsContainer,
  SegmentContainer,
  SegmentNewContainer,
  BroadcastIndexContainer,
  BroadcastShowContainer,
  BroadcastNewContainer,
  BroadcastEditContainer,
  MarketingSettingsIndexContainer,
  MailkickSubscriptionIndexContainer,
  BeefreeAddonsIndexContainer,
  AhoyMessageShowContainer,
  OfferManagerApp,
  OfferV2PreviewApp,
  OnepostComposer,
  OutreachManagerApp,
  PipelineEventsSlidingPaneApp,
  PostedPostsApp,
  ProfitAndLossApp,
  PushNotificationsApp,
  ReadMore,
  RecommendationsTableApp,
  RefundSettingsApp,
  ScheduledPostsApp,
  SemiCircle,
  SettlementApp,
  SocialManagerApp,
  TableViewApp,
  TagManagerApp,
  ContainerlessTableViewApp,
  TeamManagerApp,
  TextMessagesApp,
  TicketingSettingsApp,
  TicketingSystemsApp,
  ToolTip,
  UserMenuApp,
  UserSettingsApp,
  VenueManagerApp,
  WebOrderFormApp,
  ArtistsSearchApp,
  GlobalArtistsSearchApp,
  ArtistEventLocalShowApp,
  ArtistPipelinesApp,
  SimilarArtistsApp,
  ShowActivitySettingsApp,
  UniversalPromoCodeManagerApp,
  ArtistDetailsApp,
  PaymentDisputeManagerApp,
  PayoutManagerApp,
  FinanceSettingsManagerApp
});
